import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { __COLORS } from '../constants/colors'
import { __ROUTES } from '../constants/routes'
const SubNavList = styled.ul`
  list-style: none;
  margin: 0;
`
const NavListItem = styled.li`
  margin: 0;
  margin-bottom: 0.8rem;
  display: none;
  &:first-child {
    margin-right: 3.2rem;
    letter-spacing: 0.15em;
    display: block;
  }
`
const NavWrapper = styled.nav`
  background-color: ${__COLORS.WHITE};
  width: 100vw;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 9vh;
  padding-bottom: 2rem;
  position: fixed;
  top: 0;

  color: ${__COLORS.DARK_GREY};
  font-family: Arial;
  font-size: 1em;
  text-transform: uppercase;
  &:hover {
    height: auto;
  }

  &:hover ${SubNavList} {
    display: flex;
    flex-direction: column;
  }
  &:hover ${NavListItem} {
    display: flex;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    height: 100vh;
    position: fixed;
    &:hover {
      height: 100vh;
    }
  }
`
const NavList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`
const NavListLink = styled(Link)`
  text-decoration: none;
  color: ${__COLORS.DARK_GREY};
  &:hover {
    color: ${__COLORS.PRIMARY};
  }
`

const Header = () => (
  <NavWrapper id="navbar">
    <NavListLink to="/">
      <b>Andreas Reize</b> Thomaskantor
    </NavListLink>
    <NavList>
      <SubNavList>
        <NavListItem>
          <NavListLink to={__ROUTES.DIRIGENT}>Dirigent</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.ENGAGEMENTS}>Engagments</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.AUSBILDUNG}>Ausbildung</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.REPERTOIRE}>Repetoire</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.KONZERTARCHIV}>Konzertarchiv</NavListLink>
        </NavListItem>
      </SubNavList>
      <SubNavList>
        <NavListItem>
          <NavListLink to={__ROUTES.AKTUELL}>Aktuell</NavListLink>{' '}
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.TERMINE}>Termine</NavListLink>{' '}
        </NavListItem>
      </SubNavList>
      <SubNavList>
        <NavListItem>
          <NavListLink to={__ROUTES.PRESSESTIMMEN}>Pressestimmen</NavListLink>
        </NavListItem>
      </SubNavList>
      <SubNavList>
        <NavListItem>
          <NavListLink to={__ROUTES.MEDIEN}>Medien</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.AUDIO}>Audio</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.RADIO}>Radio</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.VIDEO}>Video</NavListLink>
        </NavListItem>
        <NavListItem>
          <NavListLink to={__ROUTES.PUBLIKATIONEN}>Publikationen</NavListLink>
        </NavListItem>
      </SubNavList>
      <SubNavList>
        <NavListItem>
          <NavListLink to={__ROUTES.KONTAKT}>Kontakt</NavListLink>
        </NavListItem>
      </SubNavList>
    </NavList>
  </NavWrapper>
)

export default Header
