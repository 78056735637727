import React from 'react'
import styled from 'styled-components'
import { __COLORS } from '../constants/colors'

const TitleWrapper = styled.div`
  width: 100vw;
  height: 80vh;
  padding: 0;
  margin: 0;
  margin-top: 20vh;
  background-color: #b2b2b3;
  display: flex;
  flex-direction: row;
`
const Title = styled.h1`
  font-family: Arial;
  color: ${__COLORS.DARK_GREY};
  text-transform: uppercase;
`
const Path = styled.p`
  font-family: Arial;
  color: ${__COLORS.WHITE};
`
const TiteleWeapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 4rem;
  padding-bottom: 15vh;
`

const ImageA = styled.div`
  height: 100%;
  width: 50vw;
  background-color: grey;
`
const Light = styled.span`
  font-weight: lighter;
`

const PageHead = () => (
  <TitleWrapper>
    <ImageA />
    <TiteleWeapper>
      <Path>Start</Path>
      <Title>
        Andreas <br />
        Reize <br />
        <Light>Thomaskantor</Light>
      </Title>
    </TiteleWeapper>
  </TitleWrapper>
)

export default PageHead
