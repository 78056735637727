export enum __ROUTES {
  HOME = '/home',
  DIRIGENT = '/dirigent',
  AUSBILDUNG = '/ausbildung',
  ENGAGEMENTS = '/engagements',
  REPERTOIRE = '/repetoire',
  KONZERTARCHIV = '/konzertarchiv',
  AKTUELL = '/aktuell',
  TERMINE = '/termine',
  PRESSESTIMMEN = '/pressestimmen',
  MEDIEN = '/medien',
  AUDIO = '/audio',
  RADIO = '/radio',
  VIDEO = '/video',
  PUBLIKATIONEN = '/publikationen',
  KONTAKT = '/kontakt',
  IMPRESSUM = '/impressum'
}
