import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { __COLORS } from '../constants/colors'
import { __ROUTES } from '../constants/routes'

const FooterWrap = styled.footer`
  background-color: ${__COLORS.DARK_GREY};
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 3vw;
`
const SiteMapWrapper = styled.div`
  background-color: ${__COLORS.DARK_GREY};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  color: ${__COLORS.WHITE};
  font-family: Arial;
  font-size: 1rem;
`
const PageGroup = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`
const SubPage = styled.li`
  margin: 0;
  margin-bottom: 0.5rem;
  &:first-child {
    font-weight: bold;
    color: ${__COLORS.WHITE};
  }
  &:last-child {
    margin-bottom: 5vh;
  }
`
const MapLink = styled(Link)`
  text-decoration: none;
  color: ${__COLORS.LIGHT_GREY};
  &:hover {
    color: ${__COLORS.WHITE};
  }
`
const MapMainLink = styled(Link)`
  text-decoration: none;
  color: ${__COLORS.WHITE};
  font-weight: bold;
  &:hover {
    color: ${__COLORS.LIGHT_GREY};
  }
`
const ArImp = styled.div`
  width: 100%;
  height: 100%;
`
const ArMark = styled(Link)`
  text-decoration: none;
  font-family: Arial;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${__COLORS.WHITE};
`
const ArFoot = styled.div`
  width: 100%;
  height: 100%;
  border-top: solid ${__COLORS.WHITE} 1px;
  margin-top: 0.8rem;
  margin-bottom: 7vh;
  padding-top: 0.8rem;
  display: flex;
  flex-direction: row;
`
const Copyright = styled.p`
  color: ${__COLORS.LIGHT_GREY};
  font-family: Arial;
  font-size: 0.8rem;
`
const ImpLink = styled(Link)`
  color: ${__COLORS.WHITE};
  font-family: Arial;
  font-size: 0.8rem;
  text-decoration: none;
  margin-left: 5rem;
  &:hover {
    color: ${__COLORS.LIGHT_GREY};
    text-decoration: underline;
  }
`
const Footer = () => (
  <FooterWrap>
    <SiteMapWrapper>
      <MapMainLink to={__ROUTES.HOME}>Andreas Reize</MapMainLink>
      <PageGroup>
        <SubPage>
          <MapMainLink to={__ROUTES.DIRIGENT}>Dirigent</MapMainLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.AUSBILDUNG}>Ausbildung</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.ENGAGEMENTS}>Engagements</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.REPERTOIRE}>Repetoire</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.KONZERTARCHIV}>Konzertarchiv</MapLink>
        </SubPage>
      </PageGroup>
      <PageGroup>
        <SubPage>
          <MapMainLink to={__ROUTES.AKTUELL}>Aktuell</MapMainLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.TERMINE}>Termine</MapLink>
        </SubPage>
      </PageGroup>
      <PageGroup>
        <SubPage>
          <MapMainLink to={__ROUTES.PRESSESTIMMEN}>Pressestimmen</MapMainLink>
        </SubPage>
      </PageGroup>
      <PageGroup>
        <SubPage>
          <MapMainLink to={__ROUTES.MEDIEN}>Medien</MapMainLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.AUSBILDUNG}>Ausbildung</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.RADIO}>Radio</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.VIDEO}>Video</MapLink>
        </SubPage>
        <SubPage>
          <MapLink to={__ROUTES.PUBLIKATIONEN}>Publikationen</MapLink>
        </SubPage>
      </PageGroup>
      <PageGroup>
        <SubPage>
          <MapMainLink to={__ROUTES.KONTAKT}>Kontakt</MapMainLink>
        </SubPage>
      </PageGroup>
    </SiteMapWrapper>
    <ArImp>
      <ArMark to={__ROUTES.HOME}>
        <b> Andreas Reize </b>Thomaskantor
      </ArMark>
      <ArFoot>
        <Copyright>
          Copyright © Andreas Reize {new Date().getFullYear()} All Rights
          reseved.
        </Copyright>
        <ImpLink to={__ROUTES.IMPRESSUM}>Impressum</ImpLink>
      </ArFoot>
    </ArImp>
  </FooterWrap>
)

export default Footer
